import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useProposal } from '../../hooks/AppContext';
import { DocumentsPersonal } from './DocumentsPersonal';
import { ContentDataPersonInside, TitleDataPersonInside, SubtitlePersonInside } from './styles';
import ProposalService from '../../services/ProposalService';
import { Proposal } from '../../models/Proposal';
import { getExactOtherFile, getExactTypeFile } from '../../utils/files';
import { FileType } from '../../enums/file.enum';
import { Modals } from '../../enums/portals.enum';
import { usePortals } from '../../hooks/PortalsContext';

const Documents = ({ formValues }: any): ReactElement => {
  const { formOpened, setFormOpened } = useProposal();
  const { handleOpenPortal } = usePortals();

  const handleCancelUpload = useCallback((reference: any) => {
    if (reference?.current?.current) {
      reference.current.current('Cancel Upload');
    }
  }, []);

  const handleUploadDocument = useCallback(
    async (file: any, documentType, reference: any) => {
      const handlerError = () => {
        reference.current?.setLoading(100);
        handleOpenPortal(Modals.ERRORDOCUMENT);
      };

      await ProposalService.postDocument(formOpened.id, documentType, file.type, file.name)
        .then(async (response) => {
          if (response === undefined || response?.statusText !== 'OK') {
            throw Error();
          }

          await ProposalService.uploadAWSService(response.data.signedUrlUpload, file, reference)
            .then((data) => {
              if (data === undefined || data?.statusText !== 'OK') {
                throw Error();
              }

              const { message } = data;
              const newData = response.data;
              newData.size = file.size;

              const allDocuments = formOpened.documents || [];
              if (message == null) {
                allDocuments.push(newData);
              }

              setFormOpened({
                ...formOpened,
                documents: allDocuments,
              });

              reference.current?.setLoading(100);
            })
            .catch(() => {
              handlerError();
            });
        })
        .catch(() => {
          handlerError();
        });
    },
    [formOpened],
  );

  const handleDeleteDocument = useCallback(
    async (item: any, fileType: FileType, documentFileType = 'Proposal', id?: string, documentId?: string) => {
      let fileObj: any = [];
      const typedFiles = getExactTypeFile(item, fileType, id);
      if (typedFiles) fileObj.push(typedFiles);
      const otherFiles = getExactOtherFile(item.documents, fileType);
      if (otherFiles) fileObj.push(otherFiles);

      await ProposalService.deleteDocuments(formOpened.id, fileObj[0].Id).then(async (resp) => {
        try {
          const allFiles: Array<any> = item.documents.filter((val: any) => val.Id !== fileObj[0].Id);

          setFormOpened({
            ...formOpened,
            documents: allFiles,
          });
        } catch (err) {
          console.log('catch', err);
        }
      });
    },
    [formOpened],
  );

  const handleViewDocument = useCallback(
    async (item: any, fileType: FileType, documentFileType = 'Proposal', id?: string) => {
      let fileObj: any = [];
      const typedFiles = getExactTypeFile(item, fileType, id);
      if (typedFiles) fileObj.push(typedFiles);
      const otherFiles = getExactOtherFile(item.documents, fileType);
      if (otherFiles) fileObj.push(otherFiles);

      const fileId = fileObj[0].Id;

      await ProposalService.viewDocuments(formOpened.id, fileId).then(async (resp) => {
        let url;
        try {
          url = JSON.parse(JSON.stringify(resp.data.url));
          window.open(url);
        } catch (err) {
          console.log('catch', err);
        }
      });
    },
    [formOpened],
  );

  useEffect(() => {}, []);

  return (
    <ContentDataPersonInside>
      <TitleDataPersonInside>Documentos pessoais</TitleDataPersonInside>
      <SubtitlePersonInside>Envio de documentação dos proponentes</SubtitlePersonInside>
      <DocumentsPersonal
        proposal={formOpened}
        onView={handleViewDocument}
        onDelete={handleDeleteDocument}
        onUpload={handleUploadDocument}
        onCancel={handleCancelUpload}
      />
    </ContentDataPersonInside>
  );
};

export default Documents;
