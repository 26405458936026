// @ts-nocheck
import React, { createContext, useContext, useState, useCallback } from 'react';
import { Signature } from '../models/Signature';
import SignatureService from '../services/SignatureService';

export const SignatureContext = createContext({});
export const useSignature = (): any => useContext(SignatureContext);

const SignatureProvider = ({ children }: any) => {
  const [stepLoading, setStepLoding] = useState(false);
  const [nextStepDisabled, setNextStepDisabled] = useState(false);
  const [prevStepDisabled, setPrevStepDisabled] = useState(false);
  const [errorSignature, setErrorSignature] = useState(false);
  const [errorGelocation, setErrorGelocation] = useState(false);
  const [errorCam, setErrorCam] = useState(false);
  const [termId, setTermId] = useState('');
  const [terms, setTerms] = useState<any | null>([]);
  const [indexAssinedTerm, setIndexAssinedTerm] = useState(0);

  const [showModal, setShowModal] = useState(false);

  const [formOpened, setFormOpened] = useState(new Signature());

  const [step, setStep] = useState(1);

  const [dataLoanFgts, setDataLoanFgts] = useState({});

  const prevStep = () => {
    setStep((previousStep: any) => previousStep - 1);
  };

  const nextStep = async (e: any) => {
    e.preventDefault();

    if (step === 1) {
      setStep((previousStep: any) => previousStep + 1);
    }
    if (step === 2) {
      setStepLoding(true);

      const fetchDataTerms = await SignatureService.acceptTerms(formOpened);
      const { response } = fetchDataTerms;

      if (response != null) {
        if (response.status === 400 && response.data.validationErrors === undefined) {
          console.log('error');
          setStepLoding(false);
          return 0;
        }

        return 0;
      }
      setFormOpened({
        ...formOpened,
      });
      setStepLoding(false);
      setStep((previousStep: any) => previousStep + 1);
    }
    if (step === 3) {
      if (termId) {
        setStep((previousStep: any) => previousStep + 1);
        return;
      }
      setStep(5);
    }
    if (step === 4) {
      const fetchDataTerms = await SignatureService.acceptCustomTerms(terms[indexAssinedTerm]?.id, formOpened);
      const { response } = fetchDataTerms;

      if (response != null) {
        if (response.status === 400 && response.data.validationErrors === undefined) {
          console.log('error');
          setStepLoding(false);
          return 0;
        }

        return 0;
      }
      setFormOpened({
        ...formOpened,
      });
      if (indexAssinedTerm === terms.length - 1) {
        setStepLoding(false);
        setStep((previousStep: any) => previousStep + 1);
        return;
      }
      setStepLoding(false);
      setIndexAssinedTerm((previousIndex: any) => previousIndex + 1);
    }
    if (step === 5) {
      setStep((previousStep: any) => previousStep + 1);
    }
    if (step === 8) {
      setStepLoding(true);
      try {
        const fetchProposalSignature = await SignatureService.sendProposal(formOpened);
        const { status } = fetchProposalSignature;

        if (status === 204) {
          setStep((previousStep: any) => previousStep + 1);
          setStepLoding(false);
        } else {
          throw new Error('Error Signature');
        }
      } catch (err) {
        console.error(err, 'error');
        setStepLoding(false);
        setErrorSignature(true);
      }
    }
  };

  const values = {
    nextStepDisabled,
    setNextStepDisabled,
    prevStepDisabled,
    setPrevStepDisabled,
    step,
    setStep,
    prevStep,
    nextStep,
    formOpened,
    setFormOpened,
    showModal,
    setShowModal,
    stepLoading,
    errorSignature,
    setErrorSignature,
    errorGelocation,
    setErrorGelocation,
    errorCam,
    setErrorCam,
    dataLoanFgts,
    setDataLoanFgts,
    termId,
    setTermId,
    terms,
    setTerms,
    indexAssinedTerm,
  };

  return <SignatureContext.Provider value={{ ...values }}>{children}</SignatureContext.Provider>;
};

export { SignatureProvider };
