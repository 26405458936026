import React from 'react';

export const Close = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.40994 7.00019L12.7099 2.71019C12.8982 2.52188 13.004 2.26649 13.004 2.00019C13.004 1.73388 12.8982 1.47849 12.7099 1.29019C12.5216 1.10188 12.2662 0.996094 11.9999 0.996094C11.7336 0.996094 11.4782 1.10188 11.2899 1.29019L6.99994 5.59019L2.70994 1.29019C2.52164 1.10188 2.26624 0.996094 1.99994 0.996094C1.73364 0.996094 1.47824 1.10188 1.28994 1.29019C1.10164 1.47849 0.995847 1.73388 0.995847 2.00019C0.995847 2.26649 1.10164 2.52188 1.28994 2.71019L5.58994 7.00019L1.28994 11.2902C1.19621 11.3832 1.12182 11.4938 1.07105 11.6156C1.02028 11.7375 0.994141 11.8682 0.994141 12.0002C0.994141 12.1322 1.02028 12.2629 1.07105 12.3848C1.12182 12.5066 1.19621 12.6172 1.28994 12.7102C1.3829 12.8039 1.4935 12.8783 1.61536 12.9291C1.73722 12.9798 1.86793 13.006 1.99994 13.006C2.13195 13.006 2.26266 12.9798 2.38452 12.9291C2.50638 12.8783 2.61698 12.8039 2.70994 12.7102L6.99994 8.41019L11.2899 12.7102C11.3829 12.8039 11.4935 12.8783 11.6154 12.9291C11.7372 12.9798 11.8679 13.006 11.9999 13.006C12.132 13.006 12.2627 12.9798 12.3845 12.9291C12.5064 12.8783 12.617 12.8039 12.7099 12.7102C12.8037 12.6172 12.8781 12.5066 12.9288 12.3848C12.9796 12.2629 13.0057 12.1322 13.0057 12.0002C13.0057 11.8682 12.9796 11.7375 12.9288 11.6156C12.8781 11.4938 12.8037 11.3832 12.7099 11.2902L8.40994 7.00019Z"
      fill="#101010"
    />
  </svg>
);

export const AvatarSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 6C16 9.44771 13.4477 11 12 11C10.5523 11 8 9.44771 8 6C8 3.79086 9.79086 2 12 2C14.2091 2 16 3.79086 16 6Z"
      stroke="#0090FF"
      strokeWidth="2"
    />
    <path
      d="M3 18.0248C3 16.7639 3.77608 15.681 4.92023 15.337C7.01957 14.7058 9.86554 14 12 14C14.1345 14 16.9804 14.7058 19.0798 15.337C20.2239 15.681 21 16.7639 21 18.0248V21H3V18.0248Z"
      stroke="#0090FF"
      strokeWidth="2"
    />
  </svg>
);

export const IdentificationSvg = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 4C5 2.89543 5.89543 2 7 2H19.7574C20.2878 2 20.7965 2.21071 21.1716 2.58579L26.4142 7.82843C26.7893 8.2035 27 8.71221 27 9.24264V28C27 29.1046 26.1046 30 25 30H7C5.89543 30 5 29.1046 5 28V4Z"
        stroke="#0090FF"
        strokeWidth="2"
      />
      <g opacity="0.5">
        <path
          d="M18.4993 12.5C18.4993 14.7477 16.847 15.7 15.9993 15.7C15.1516 15.7 13.4993 14.7477 13.4993 12.5C13.4993 11.1193 14.6186 10 15.9993 10C17.38 10 18.4993 11.1193 18.4993 12.5Z"
          stroke="#0090FF"
          strokeWidth="2"
        />
        <path
          d="M10 22.3498C10 21.0827 10.7781 20.0097 11.9079 19.6994C13.2591 19.3284 14.8088 19 16 19C17.1912 19 18.7409 19.3284 20.0921 19.6994C21.2219 20.0097 22 21.0827 22 22.3498V23.3L10 23.3L10 22.3498Z"
          stroke="#0090FF"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export const ErrorProposal = () => (
  <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M107.2 52.8008C102.532 52.8008 98.0556 50.9465 94.7549 47.6459C91.4543 44.3452 89.6 39.8686 89.6 35.2008V27.3856C89.6001 27.0461 89.5333 26.7099 89.4035 26.3963C89.2736 26.0826 89.0832 25.7977 88.8432 25.5576C88.6031 25.3176 88.3181 25.1272 88.0045 24.9973C87.6908 24.8675 87.3547 24.8007 87.0152 24.8008H60C55.3322 24.8008 50.8556 26.6551 47.5549 29.9557C44.2543 33.2563 42.4 37.733 42.4 42.4008V119.201C42.4 123.869 44.2543 128.345 47.5549 131.646C50.8556 134.947 55.3322 136.801 60 136.801H100C104.668 136.801 109.144 134.947 112.445 131.646C115.746 128.345 117.6 123.869 117.6 119.201V55.3856C117.6 55.0461 117.533 54.7099 117.403 54.3963C117.274 54.0826 117.083 53.7977 116.843 53.5576C116.603 53.3176 116.318 53.1272 116.004 52.9973C115.691 52.8675 115.355 52.8007 115.015 52.8008H107.2Z"
      stroke="#101010"
      strokeWidth="4"
      strokeLinejoin="round"
    />
    <path d="M88.8176 25.5332L116.825 53.5764" stroke="#101010" strokeWidth="4" strokeLinejoin="round" />
    <path
      d="M111.204 60.7711V119.203C111.204 120.674 110.914 122.13 110.351 123.489C109.789 124.848 108.964 126.083 107.924 127.123C106.884 128.163 105.649 128.988 104.29 129.551C102.931 130.113 101.475 130.403 100.004 130.403H60.004C57.0336 130.403 54.1848 129.223 52.0844 127.123C49.984 125.022 48.804 122.174 48.804 119.203V42.4031C48.804 39.4327 49.984 36.5839 52.0844 34.4835C54.1848 32.3831 57.0336 31.2031 60.004 31.2031H81.6352C82.0511 31.2031 82.45 31.3683 82.7442 31.6623C83.0384 31.9564 83.2038 32.3552 83.204 32.7711V35.2031C83.204 41.5683 85.7326 47.6728 90.2334 52.1737C94.7343 56.6746 100.839 59.2031 107.204 59.2031H109.635C110.051 59.2031 110.45 59.3683 110.744 59.6623C111.038 59.9564 111.204 60.3552 111.204 60.7711Z"
      fill="#F3F3F3"
    />
    <path d="M74.4 56.8008H61.6" stroke="#101010" strokeWidth="4" strokeLinecap="round" />
    <path d="M98.4 88.8008H61.6" stroke="#101010" strokeWidth="4" strokeLinecap="round" />
    <path d="M98.4 104.801H61.6" stroke="#101010" strokeWidth="4" strokeLinecap="round" />
    <path d="M98.4 72.8008H61.6" stroke="#101010" strokeWidth="4" strokeLinecap="round" />
    <rect x="108.8" y="60.8008" width="38.4" height="38.4" rx="12" fill="#F83D44" />
    <path
      d="M135.271 86.3213L129.076 80.1259L135.271 73.9306C135.521 73.6723 135.521 73.2627 135.271 73.0043C135.015 72.7395 134.593 72.7321 134.329 72.9879L128.133 79.1833L121.938 72.988C121.68 72.7385 121.27 72.7385 121.012 72.988C120.747 73.2438 120.739 73.6658 120.995 73.9306L127.191 80.1259L120.995 86.3213C120.87 86.4463 120.8 86.6158 120.8 86.7925C120.8 87.1607 121.098 87.4592 121.467 87.4593C121.643 87.4594 121.813 87.3891 121.938 87.2639L128.133 81.0686L134.329 87.264C134.453 87.3892 134.623 87.4595 134.8 87.4593C134.977 87.4592 135.146 87.389 135.271 87.2641C135.531 87.0038 135.532 86.5817 135.271 86.3213Z"
      fill="white"
    />
  </svg>
);

export const AnalysisProposal = () => (
  <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2159_12575)">
      <path
        d="M42.48 106.24V119.2C42.48 123.84 44.32 128.32 47.6 131.68C50.88 134.96 55.36 136.8 60.08 136.8H100.08C104.72 136.8 109.2 134.96 112.56 131.68C115.84 128.4 117.68 123.92 117.68 119.2V55.36C117.68 55.04 117.6 54.72 117.52 54.4C117.36 54.08 117.2 53.84 116.96 53.6C116.72 53.36 116.4 53.2 116.16 53.04C115.92 52.88 115.52 52.88 115.2 52.88H107.36C102.72 52.88 98.24 51.04 94.88 47.76C91.6 44.48 89.76 40 89.76 35.28V27.36C89.76 27.04 89.68 26.72 89.6 26.4C89.44 26.08 89.28 25.84 89.04 25.6C88.8 25.36 88.48 25.2 88.24 25.04C87.76 24.88 87.44 24.8 87.12 24.8H60.08C55.44 24.8 50.96 26.64 47.6 29.92C44.32 33.2 42.48 37.68 42.48 42.4V63.52"
        stroke="#101010"
        strokeWidth="3.9997"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M88.88 25.52L116.88 53.6" stroke="#101010" strokeWidth="4" strokeLinejoin="round" />
      <path
        d="M111.28 60.8V119.2C111.28 120.64 110.96 122.16 110.4 123.52C109.84 124.88 109.04 126.08 108 127.12C106.96 128.16 105.76 128.96 104.4 129.52C103.04 130.08 101.6 130.4 100.08 130.4H60.08C57.12 130.4 54.24 129.2 52.16 127.12C50.08 125.04 48.88 122.16 48.88 119.2V42.4C48.88 39.44 50.08 36.56 52.16 34.48C54.24 32.4 57.12 31.2 60.08 31.2H81.68C82.08 31.2 82.48 31.36 82.8 31.68C83.12 32 83.28 32.4 83.28 32.8V35.2C83.28 41.6 85.84 47.68 90.32 52.16C94.8 56.64 100.96 59.2 107.28 59.2H109.68C110.08 59.2 110.48 59.36 110.8 59.68C111.12 59.92 111.28 60.32 111.28 60.8Z"
        fill="#F3F3F3"
      />
      <path d="M98.48 100.96H84.72" stroke="#101010" strokeWidth="4" strokeLinecap="round" />
      <path d="M98.48 84.96H77.68" stroke="#101010" strokeWidth="4" strokeLinecap="round" />
      <path d="M98.48 68.96H74.16" stroke="#101010" strokeWidth="4" strokeLinecap="round" />
      <path
        d="M51.76 102.32C61.3476 102.32 69.12 94.5477 69.12 84.96C69.12 75.3724 61.3476 67.6 51.76 67.6C42.1723 67.6 34.4 75.3724 34.4 84.96C34.4 94.5477 42.1723 102.32 51.76 102.32Z"
        fill="#F3F3F3"
      />
      <path
        d="M51.76 102.32C61.3476 102.32 69.12 94.5477 69.12 84.96C69.12 75.3724 61.3476 67.6 51.76 67.6C42.1723 67.6 34.4 75.3724 34.4 84.96C34.4 94.5477 42.1723 102.32 51.76 102.32Z"
        stroke="#101010"
        strokeWidth="4"
      />
      <path
        d="M85.2 118.48C83.84 119.84 81.6 119.84 80.24 118.48L70.16 108.4C68.8 107.04 68.8 104.8 70.16 103.44C71.52 102.08 73.76 102.08 75.12 103.44L85.2 113.52C86.64 114.88 86.64 117.04 85.2 118.48Z"
        stroke="#101010"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path d="M64.24 97.44L70.24 103.44" stroke="#101010" strokeWidth="4" strokeLinecap="round" />
      <path
        d="M51.76 76.08L54.4 81.36L60.16 82.24L56 86.32L56.96 92.16L51.76 89.36L46.56 92.16L47.52 86.32L43.36 82.24L49.12 81.36L51.76 76.08Z"
        stroke="#101010"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <rect x="108.8" y="60.8" width="38.4" height="38.4" rx="12" fill="#0090FF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M121.6 71.1682C121.317 71.1682 121.088 71.3974 121.088 71.6802C121.088 71.963 121.317 72.1922 121.6 72.1922H122.448V74.3957L122.448 74.3968C122.449 74.8459 122.544 75.2898 122.728 75.6996C122.912 76.1094 123.18 76.476 123.515 76.7754L127.253 80.1226L123.518 83.467C123.183 83.7665 122.915 84.133 122.731 84.5427C122.548 84.9525 122.452 85.3964 122.451 85.8456L122.451 85.8466V88.0501H121.6C121.317 88.0501 121.088 88.2793 121.088 88.5621C121.088 88.8449 121.317 89.0741 121.6 89.0741H122.959L122.963 89.0741L122.968 89.0741H133.077L133.082 89.0741L133.086 89.0741H134.433C134.715 89.0741 134.945 88.8449 134.945 88.5621C134.945 88.2793 134.715 88.0501 134.433 88.0501H133.594L133.594 85.8466L133.594 85.8456C133.593 85.3964 133.497 84.9525 133.314 84.5427C133.13 84.1329 132.862 83.7663 132.527 83.4669L128.788 80.1196L132.521 76.7752C132.855 76.4759 133.123 76.1094 133.307 75.6996C133.491 75.2898 133.586 74.8459 133.587 74.3968V72.1922H134.433C134.715 72.1922 134.945 71.963 134.945 71.6802C134.945 71.3974 134.715 71.1682 134.433 71.1682H121.6ZM132.563 72.1922H123.472V74.3952C123.473 74.7004 123.538 75.0021 123.663 75.2807C123.788 75.5593 123.97 75.8086 124.197 76.0122L128.019 79.4338L131.838 76.0125C132.065 75.8089 132.248 75.5593 132.373 75.2807C132.498 75.0021 132.563 74.7005 132.563 74.3953V72.1922ZM132.57 88.0501V85.8471C132.569 85.5418 132.504 85.2402 132.379 84.9617C132.254 84.683 132.072 84.4337 131.844 84.2301L128.022 80.8084L124.201 84.23C123.973 84.4336 123.791 84.683 123.666 84.9617C123.541 85.2402 123.476 85.5419 123.475 85.8471V88.0501H132.57Z"
      fill="white"
    />
    <defs>
      <clipPath id="clip0_2159_12575">
        <rect width="86.48" height="115.2" fill="white" transform="translate(32.8 23.2)" />
      </clipPath>
    </defs>
  </svg>
);

export const PendingDocument = () => {
  return (
    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="160" height="160" fill="white" />
      <path
        d="M109.553 54.7451C104.428 54.7451 99.512 52.709 95.8878 49.0848C92.2635 45.4606 90.2275 40.545 90.2275 35.4196V26.8382C90.2276 26.4655 90.1542 26.0963 90.0116 25.7519C89.8691 25.4075 89.66 25.0946 89.3964 24.831C89.1328 24.5675 88.8199 24.3584 88.4755 24.2158C88.1311 24.0732 87.762 23.9999 87.3892 24H57.7255C52.6 24 47.6845 26.0361 44.0603 29.6603C40.4361 33.2845 38.4 38.2001 38.4 43.3255V116.675C38.4 121.8 40.4361 126.715 44.0603 130.34C47.6845 133.964 52.6 136 57.7255 136H101.647C106.773 136 111.688 133.964 115.312 130.34C118.936 126.715 120.973 121.8 120.973 116.675V57.5833C120.973 57.2106 120.899 56.8414 120.757 56.497C120.614 56.1526 120.405 55.8397 120.142 55.5761C119.878 55.3126 119.565 55.1035 119.221 54.9609C118.876 54.8183 118.507 54.745 118.134 54.7451H109.553Z"
        stroke="#101010"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path d="M89.3682 24.8038L120.121 55.5964" stroke="#101010" strokeWidth="4" strokeLinejoin="round" />
      <path
        d="M113.95 63.4969V116.677C113.95 118.292 113.631 119.891 113.013 121.383C112.395 122.875 111.49 124.231 110.348 125.373C109.206 126.515 107.85 127.421 106.358 128.039C104.866 128.657 103.267 128.975 101.652 128.975H57.7299C54.4683 128.975 51.3402 127.679 49.0339 125.373C46.7276 123.067 45.4319 119.939 45.4319 116.677V43.3281C45.4319 40.0664 46.7276 36.9384 49.0339 34.632C51.3402 32.3257 54.4683 31.03 57.7299 31.03H81.4818C81.9386 31.03 82.3766 31.2114 82.6996 31.5343C83.0226 31.8571 83.2042 32.295 83.2045 32.7518V35.4222C83.2045 42.4114 85.9809 49.1144 90.923 54.0565C95.8652 58.9987 102.568 61.7751 109.557 61.7751H112.227C112.684 61.7751 113.122 61.9565 113.445 62.2794C113.768 62.6022 113.949 63.0401 113.95 63.4969Z"
        fill="#F3F3F3"
      />
      <ellipse cx="79.4667" cy="81.7568" rx="10.5412" ry="10.5412" stroke="#101010" strokeWidth="4" />
      <path
        d="M97.9138 110.745C97.9138 100.557 89.6548 92.2979 79.4668 92.2979C69.2787 92.2979 61.0197 100.557 61.0197 110.745"
        stroke="#101010"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="108.8" y="60.8" width="38.4" height="38.4" rx="12" fill="#FFB82E" />
      <path
        d="M128 82.6667C128.354 82.6667 128.693 82.5262 128.943 82.2761C129.193 82.0261 129.333 81.687 129.333 81.3333V73.3333C129.333 72.9797 129.193 72.6406 128.943 72.3905C128.693 72.1405 128.354 72 128 72C127.646 72 127.307 72.1405 127.057 72.3905C126.807 72.6406 126.667 72.9797 126.667 73.3333V81.3333C126.667 81.687 126.807 82.0261 127.057 82.2761C127.307 82.5262 127.646 82.6667 128 82.6667ZM128 88C128.33 88 128.652 87.9022 128.926 87.7191C129.2 87.536 129.414 87.2757 129.54 86.9711C129.666 86.6666 129.699 86.3315 129.635 86.0082C129.57 85.6849 129.412 85.3879 129.179 85.1548C128.945 84.9217 128.648 84.763 128.325 84.6987C128.002 84.6344 127.667 84.6674 127.362 84.7935C127.058 84.9197 126.797 85.1333 126.614 85.4074C126.431 85.6815 126.333 86.0037 126.333 86.3333C126.333 86.7754 126.509 87.1993 126.821 87.5118C127.134 87.8244 127.558 88 128 88Z"
        fill="white"
      />
    </svg>
  );
};

export const Phone = () => (
  <svg width="52" height="56" viewBox="0 0 52 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.0996 2.8H5.69961C4.15321 2.8 2.89961 4.0536 2.89961 5.6V50.4C2.89961 51.9464 4.15321 53.2 5.69961 53.2H28.0996C29.646 53.2 30.8996 51.9464 30.8996 50.4V5.6C30.8996 4.0536 29.646 2.8 28.0996 2.8ZM5.69961 0C2.60681 0 0.0996094 2.50721 0.0996094 5.6V50.4C0.0996094 53.4928 2.60682 56 5.69961 56H28.0996C31.1924 56 33.6996 53.4928 33.6996 50.4V5.6C33.6996 2.50721 31.1924 0 28.0996 0H5.69961Z"
      fill="#626568"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2998 0V4.2C11.2998 4.9732 11.9266 5.6 12.6998 5.6H21.0998C21.873 5.6 22.4998 4.9732 22.4998 4.2V0H11.2998Z"
      fill="#626568"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9002 22.3999C14.5806 22.3999 12.7002 24.2803 12.7002 26.5999V32.1999V34.9999V41.1789C12.7002 43.6238 15.6139 44.8943 17.4055 43.2307L21.7464 39.1999H47.7002C50.0198 39.1999 51.9002 37.3195 51.9002 34.9999V26.5999C51.9002 24.2803 50.0198 22.3999 47.7002 22.3999H16.9002Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.6468 36.3999H47.7002C48.4734 36.3999 49.1002 35.7731 49.1002 34.9999V26.5999C49.1002 25.8267 48.4734 25.1999 47.7002 25.1999H16.9002C16.127 25.1999 15.5002 25.8267 15.5002 26.5999V41.1789L20.6468 36.3999ZM12.7002 26.5999C12.7002 24.2803 14.5806 22.3999 16.9002 22.3999H47.7002C50.0198 22.3999 51.9002 24.2803 51.9002 26.5999V34.9999C51.9002 37.3195 50.0198 39.1999 47.7002 39.1999H21.7464L17.4055 43.2307C15.6139 44.8943 12.7002 43.6238 12.7002 41.1789V26.5999Z"
      fill="#626568"
    />
    <path
      d="M21.0996 30.8C21.0996 29.2536 22.3532 28 23.8996 28C25.446 28 26.6996 29.2536 26.6996 30.8C26.6996 32.3464 25.446 33.6 23.8996 33.6C22.3532 33.6 21.0996 32.3464 21.0996 30.8Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.8996 28C22.3532 28 21.0996 29.2536 21.0996 30.8C21.0996 32.3464 22.3532 33.6 23.8996 33.6C25.446 33.6 26.6996 32.3464 26.6996 30.8C26.6996 29.2536 25.446 28 23.8996 28Z"
      fill="#626568"
    />
    <path
      d="M29.499 30.8C29.499 29.2536 30.7526 28 32.299 28C33.8454 28 35.099 29.2536 35.099 30.8C35.099 32.3464 33.8454 33.6 32.299 33.6C30.7526 33.6 29.499 32.3464 29.499 30.8Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.299 28C30.7526 28 29.499 29.2536 29.499 30.8C29.499 32.3464 30.7526 33.6 32.299 33.6C33.8454 33.6 35.099 32.3464 35.099 30.8C35.099 29.2536 33.8454 28 32.299 28Z"
      fill="#626568"
    />
    <path
      d="M37.8994 30.8C37.8994 29.2536 39.153 28 40.6994 28C42.2458 28 43.4994 29.2536 43.4994 30.8C43.4994 32.3464 42.2458 33.6 40.6994 33.6C39.153 33.6 37.8994 32.3464 37.8994 30.8Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.6994 28C39.153 28 37.8994 29.2536 37.8994 30.8C37.8994 32.3464 39.153 33.6 40.6994 33.6C42.2458 33.6 43.4994 32.3464 43.4994 30.8C43.4994 29.2536 42.2458 28 40.6994 28Z"
      fill="#626568"
    />
  </svg>
);

export const Copy = () => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 6.94C17.9896 6.84814 17.9695 6.75763 17.94 6.67V6.58C17.8919 6.47718 17.8278 6.38267 17.75 6.3L11.75 0.3C11.6673 0.222216 11.5728 0.158081 11.47 0.11C11.4402 0.10576 11.4099 0.10576 11.38 0.11C11.2784 0.0517413 11.1662 0.0143442 11.05 0H7C6.20435 0 5.44129 0.316071 4.87868 0.87868C4.31607 1.44129 4 2.20435 4 3V4H3C2.20435 4 1.44129 4.31607 0.87868 4.87868C0.316071 5.44129 0 6.20435 0 7V17C0 17.7957 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H11C11.7956 20 12.5587 19.6839 13.1213 19.1213C13.6839 18.5587 14 17.7957 14 17V16H15C15.7956 16 16.5587 15.6839 17.1213 15.1213C17.6839 14.5587 18 13.7957 18 13V7C18 7 18 7 18 6.94ZM12 3.41L14.59 6H13C12.7348 6 12.4804 5.89464 12.2929 5.70711C12.1054 5.51957 12 5.26522 12 5V3.41ZM12 17C12 17.2652 11.8946 17.5196 11.7071 17.7071C11.5196 17.8946 11.2652 18 11 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V7C2 6.73478 2.10536 6.48043 2.29289 6.29289C2.48043 6.10536 2.73478 6 3 6H4V13C4 13.7957 4.31607 14.5587 4.87868 15.1213C5.44129 15.6839 6.20435 16 7 16H12V17ZM16 13C16 13.2652 15.8946 13.5196 15.7071 13.7071C15.5196 13.8946 15.2652 14 15 14H7C6.73478 14 6.48043 13.8946 6.29289 13.7071C6.10536 13.5196 6 13.2652 6 13V3C6 2.73478 6.10536 2.48043 6.29289 2.29289C6.48043 2.10536 6.73478 2 7 2H10V5C10 5.79565 10.3161 6.55871 10.8787 7.12132C11.4413 7.68393 12.2044 8 13 8H16V13Z"
        fill="white"
      />
    </svg>
  );
};

export const CheckCopy = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.72 6.79L8.43 11.09L6.78 9.44C6.69036 9.33532 6.58004 9.2503 6.45597 9.19028C6.33191 9.13025 6.19678 9.09652 6.05906 9.0912C5.92134 9.08588 5.78401 9.10909 5.65568 9.15936C5.52736 9.20964 5.41081 9.2859 5.31335 9.38335C5.2159 9.48081 5.13964 9.59735 5.08937 9.72568C5.03909 9.854 5.01589 9.99133 5.02121 10.1291C5.02653 10.2668 5.06026 10.4019 5.12028 10.526C5.1803 10.65 5.26532 10.7604 5.37 10.85L7.72 13.21C7.81344 13.3027 7.92426 13.376 8.0461 13.4258C8.16793 13.4755 8.2984 13.5008 8.43 13.5C8.69234 13.4989 8.94374 13.3947 9.13 13.21L14.13 8.21C14.2237 8.11704 14.2981 8.00644 14.3489 7.88458C14.3997 7.76272 14.4258 7.63201 14.4258 7.5C14.4258 7.36799 14.3997 7.23728 14.3489 7.11542C14.2981 6.99356 14.2237 6.88296 14.13 6.79C13.9426 6.60375 13.6892 6.49921 13.425 6.49921C13.1608 6.49921 12.9074 6.60375 12.72 6.79ZM10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87103 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23985 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18Z"
        fill="white"
      />
    </svg>
  );
};
