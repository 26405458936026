import React, { useState } from 'react';

//CONTEXT
import { useProposal } from '../../../hooks/AppContext';

// COMPONENTS
import ToolTip from '../../../components/UI/Tooltip';
import { Badge } from '../Badge';
import { Kebab } from '../Kebab';
import { TableSkeleton } from './TableSkeleton';

// ASSETS
import { Copy, Generate } from './svgs';

//STYLES
import { ContentKebab, GenerateLink, ItemKebab, SolvePencence, Tbody, Td, Tr, Url } from './styles';

//UTILS
import { getStatusByStep } from '../../../utils/badges';
import { getFullDateText } from '../../../utils/formatDate';
import { documentFormat } from '../../../utils/formatDocument';
import MediaQuery from '../MediaQuery/MediaQuery';

// CONSTANTS
import { LoanTypeLabel } from '../../../constants/loan-types';

// SERVICES
import ProposalService from '../../../services/ProposalService';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../../hooks/ToastContext';

interface ComponentProps {
  items: any;
  onReload: any;
  onQuit: any;
  loading: boolean;
  isTableOverlay: boolean;
  forceResponsive: boolean;
}

const statusToSolvePendence = [
  'PENDING_DOCUMENTS',
  'PENDING_PURCHASE_MARGIN',
  'PENDING_PURCHASE_MARGIN_WRONG_PROPOSAL',
];

const TableBody = ({ items, onQuit, loading, isTableOverlay, forceResponsive = false }: ComponentProps): any => {
  const navigate = useNavigate();
  const { addToast } = useToast();

  const {
    formOpened,
    setFormOpened,
    setStep,
    setIsDash,
    setProposalId,
    tableConfiguration,
    getPageSize,
    pageSize,
    setPageSize,
    setPrevStepDisabled,
  } = useProposal();

  const [copySuccess, setCopySuccess] = useState('');
  const copyToClipBoard = async (copyMe: any) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copiado!');
    } catch (err) {
      setCopySuccess('Falha ao copiar!');
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const tableOverlayItems = [1, 2, 3];

  const getTableConfig = (id: number) => {
    return tableConfiguration.find((config: any) => config.id === id);
  };

  const RenderColumn = ({ children, id, status }: { children: any; id: number; status: boolean }) => {
    if (!status) {
      return null;
    }

    const isTableOverlayItems = !isTableOverlay && tableOverlayItems.includes(id);

    if ((isTableOverlay && !tableOverlayItems.includes(id)) || (forceResponsive && isTableOverlayItems)) {
      return null;
    }

    if ((!isTableOverlay && !tableOverlayItems.includes(id)) || (isTableOverlay && tableOverlayItems.includes(id))) {
      return children;
    }

    return <MediaQuery maxWidth={1365}>{children}</MediaQuery>;
  };

  const getSkeletonColumns = () => {
    if (isTableOverlay) {
      return [
        {
          className: 'SkeletonText',
        },
        {
          className: 'SkeletonText',
        },
        {
          className: 'SkeletonText',
        },
        {
          className: 'actions-quit',
        },
      ];
    }

    let newConfig = tableConfiguration;
    newConfig = newConfig.slice(3);

    if (forceResponsive && !isTableOverlay) {
      return newConfig
        .filter((config: any) => config.status)
        .map((column: any) => {
          return {
            className: 'SkeletonText',
          };
        });
    }

    const items: any = [];
    tableConfiguration
      .filter((config: any) => config.status)
      .map((column: any, idx: number) => {
        if (tableOverlayItems.includes(column.id)) {
          return items.push({
            className: column.id === 7 ? 'SkeletonBadge' : 'SkeletonText',
          });
        }

        items.push({
          className: 'SkeletonText',
        });
      });

    items.push({
      className: 'actions-quit',
    });

    return items;
  };

  const renderBadge = (item: any) => {
    const badge = getStatusByStep(item?.status?.toUpperCase());

    return <Badge label={badge.label} color={badge.color} className="TableBadge" />;
  };

  const handleLinkProposal = (e: any) => {
    const proposalId = e.target.closest('tr').dataset.id;
    const fileObj = items.find((val: any) => val.id === proposalId);

    ProposalService.fetchLinkSignature(fileObj.documentNumber, fileObj.type).then(async (resp) => {
      try {
        location.reload();
      } catch (err) {
        console.log('catch', err);
      }
    });
  };

  const onSolvePendence = async (e: any) => {
    const proposal = e.target.closest('tr').firstChild.innerHTML;
    setIsLoading(true);
    const { data } = await ProposalService.getProposal(proposal);
    if (isLoading) return;
    try {
      if (data.status === 'PENDING_DOCUMENTS') {
        setFormOpened({
          ...data,
          loanDetails: data.loanDetails.map((loanDetail: any) => {
            return {
              ...loanDetail,
              documentsProposal: data.documentList,
              purchaseMargin: loanDetail.purchaseMargin / 100,
            };
          }),
        });
        setPrevStepDisabled(true);
        if (!data) return;
        navigate('/proposal');
        setStep(3);
      }
      if (data.status === 'PENDING_PURCHASE_MARGIN_WRONG_PROPOSAL' || data.status === 'PENDING_PURCHASE_MARGIN') {
        setFormOpened({
          ...data,
          loanDetails: data.loanDetails.map((loanDetail: any) => {
            return {
              ...loanDetail,
              purchaseMargin: loanDetail.purchaseMargin / 100,
            };
          }),
        });
        navigate('/proposal');
        setStep(2);
      }
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Erro ao buscar a proposta!',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const keababItems = (item: any) => {
    return (
      <>
        {(item.status === 'PENDING_SIGN' ||
          item.status === 'AWAITING_SIGNATURE' ||
          item.status === 'PENDING_DOCUMENTS_SIGN') && (
          <ItemKebab className={`copy`}>
            {item.url ? (
              <>
                {/* <p>{copySuccess}</p> */}
                <Url>{item.url}</Url>
                <div onClick={() => copyToClipBoard(item.url)}>
                  <Copy />
                </div>
              </>
            ) : (
              <GenerateLink onClick={handleLinkProposal}>
                Gerar link <Generate />
              </GenerateLink>
            )}
          </ItemKebab>
        )}
        <ItemKebab
          className={`${!statusToSolvePendence.includes(item.status) || isLoading ? 'disabled' : ''}`}
          onClick={statusToSolvePendence.includes(item.status) ? onSolvePendence : undefined}
        >
          <SolvePencence className={`${!statusToSolvePendence.includes(item.status) || isLoading ? 'disabled' : ''}`}>
            Resolver pendência
          </SolvePencence>
        </ItemKebab>
      </>
    );
  };

  const renderKebab = (item: any) => {
    return (
      <Kebab>
        <ContentKebab>{keababItems(item)}</ContentKebab>
      </Kebab>
    );
  };

  return (
    <>
      <Tbody>
        {loading || isLoading ? (
          <TableSkeleton totalItems={10} columns={getSkeletonColumns()} forceResponsive={forceResponsive} />
        ) : (
          <>
            {items.map((item: any, key: string) => {
              return (
                <Tr key={`row-${key}`} data-id={item.id} className="RowBody">
                  <RenderColumn status={getTableConfig(1).status} id={1}>
                    <Td>{item.proposalNumber}</Td>
                  </RenderColumn>

                  <RenderColumn status={getTableConfig(2).status} id={2}>
                    <Td className="LoanType">
                      <div className="LoanType">{LoanTypeLabel[item.loanType]}</div>
                    </Td>
                  </RenderColumn>

                  <RenderColumn status={getTableConfig(3).status} id={3}>
                    <Td className="Name">
                      <ToolTip origin={'list'} title={item.name}>
                        <div className="Name">{item.name}</div>
                      </ToolTip>
                    </Td>
                  </RenderColumn>

                  <RenderColumn status={getTableConfig(4).status} id={4}>
                    <Td>{documentFormat(item.documentNumber)}</Td>
                  </RenderColumn>

                  <RenderColumn status={getTableConfig(5).status} id={5}>
                    <Td className="Covenant">
                      <ToolTip origin={'list'} title={item.cnpjConsigneeName}>
                        <div className="Name"> {item.cnpjConsigneeName}</div>
                      </ToolTip>
                    </Td>
                  </RenderColumn>

                  <RenderColumn status={getTableConfig(6).status} id={6}>
                    <Td>{getFullDateText(item.created)}</Td>
                  </RenderColumn>

                  <RenderColumn status={getTableConfig(7).status} id={7}>
                    <Td>{renderBadge(item)}</Td>
                  </RenderColumn>

                  <Td className="actions-quit" data-id={item.id}>
                    {renderKebab(item)}
                  </Td>
                </Tr>
              );
            })}
          </>
        )}
      </Tbody>
    </>
  );
};

export default TableBody;
