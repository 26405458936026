import styled, { css } from 'styled-components';

interface proposContentCam {
  errorPermissionCam?: any;
}

export const Container = styled.div`
  overflow: auto;
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  // viewport unit on mobile
  height: calc(var(--vh, 1vh) * 100);
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`;

export const ContentSteps = styled.div`
  flex-direction: column;
  width: 100%;
  padding: 0 32px;
  padding: 0 24px 98px 24px;

  iframe {
    border: none;
    width: 100%;
    height: 100vh;
  }
  &.errorPermissionGeolocation {
    padding-top: 24px;
    padding-bottom: 35px;
    justify-content: flex-start;
  }
  &.terms {
    justify-content: center;
  }
`;

export const HeaderSteps = styled.div`
  padding: 32px 20px;
  align-items: center;
  justify-content: center;
  position: relative;

  button {
    background-color: inherit;
    border: none;
    position: absolute;
    left: 18px;
  }
  h4 {
    margin: 0;
  }
`;

export const TitleSteps = styled.h4`
  font-size: 16px;
  line-height: 24px;
  font-family: 'Clan Web Pro Book';
  color: var(--color-black-primary);
  margin: 30px 22px 38px;
  text-align: center;
`;

// List Proposal
export const ContentProposal = styled.div`
  flex-direction: column;
`;

export const ActionSignProposal = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px;
  z-index: 999;
  button {
    width: 100%;
  }
`;

export const LinkDownload = styled.div`
  margin-top: 24px;
  align-items: center;
  a {
    text-decoration: none;
    font-size: 16px;
    color: var(--color-blue-primary);
    margin-left: 12px;
    font-family: 'Clan Web Pro News';
    font-size: 10px;
  }
  svg {
    width: 12px;
    height: 16px;
  }
`;

// SCREEN SIGNSIGNATURE
export const ContainerSelfieTips = styled.div`
  background: #f6f6f6;
  padding: 24px;
  width: 100%;
  flex-direction: column;
  height: calc(100% - 91px);
  overflow-y: auto;
`;

export const TitleContentSelfieTips = styled.h4`
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: var(--color-black-primary);
  margin-bottom: 30px;
`;

export const ContentSelfieTips = styled.div`
  background: #ffffff;
  border-radius: 10px;
  flex-direction: column;
  padding: 26px 32px;
  height: 100%;
`;
export const ItemSfieTips = styled.div`
  align-items: center;
  margin-bottom: 34px;
`;

export const IconsSelfieTipsIcons = styled.div`
  margin-right: 25px;
  margin-left: 8px;
`;
export const ParagraphSelfieTips = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: var(--color-black-primary);
`;

// Selfie
export const ContainerSelfie = styled.div<proposContentCam>`
  flex-direction: column;
  width: 100%;
  background-color: var(--color-white-primary);
  justify-content: space-between;
  &.errorPermissionCam {
    background-color: inherit;
    padding: 24px 16px 35px;
  }

  video {
    height: 100%;
  }
`;

//Sucess Selfie
export const ContainerSucess = styled.div`
  width: 100%;
`;

export const CotentSucess = styled.div`
  background: #101010;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--color-black-primary);
`;

export const TitleSucess = styled.h4`
  font-size: 30px;
  line-height: 33px;
  font-family: 'Clan Web Pro Bold';
  color: var(--color-white-primary);
  margin-left: 24px;
`;

// SCREEN SIGNSIGNATURE
export const ContainerSign = styled.div`
  background: #f6f6f6;
  padding: 24px;
  width: 100%;
`;
export const CotentSign = styled.div`
  flex-direction: column;
  width: 100%;
  background: var(--color-white-primary);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 32px;
  text-align: center;
  height: calc(100% - 91px);
`;
export const TitleSign = styled.h4`
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 24px;
  margin-top: 31px;
  font-family: 'Clan Web Pro News';
`;
export const TextSign = styled.p`
  font-size: 16px;
  line-height: 24px;
`;
export const DataLoan = styled.div`
  border-top: 1px solid #f5f6f7;
  padding-top: 32px;
  width: 100%;
  margin-top: 32px;
  justify-content: space-around;
`;
export const DataValueLoan = styled.div`
  font-size: 16px;
  line-height: 24px;
  flex-direction: column;
  align-items: center;
`;
export const ParagraphDataValueLoan = styled.p`
  font-family: 'Clan Web Pro News';
  font-size: 12px;
  line-height: 24px;
  color: #8e8e8e;
`;
export const SubParagraphDataValueLoan = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: var(--color-black-primary);
  font-family: 'Clan Web Pro News';
`;

export const IconDataValueLoan = styled.div`
  background: #f5f6f7;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  margin-bottom: 12px;
  align-items: center;
  justify-content: center;
`;

// ModalAction
export const ModalAction = styled.div`
  flex-direction: column;
  margin-top: 24px;
  width: 100%;
  > button:last-child {
    margin-left: 0px !important;
    margin-top: 8px;
    font-family: 'Clan Web Pro Book';
  }
`;

// ---- Error Page
export const ErrorTitle = styled.h4`
  font-size: 18px;
  line-height: 30px;
  color: var(--color-black-primary);
  font-family: 'Clan Web Pro Book';
  margin-bottom: 24px;
`;
export const Error = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1;
`;
