import React, { useEffect } from 'react';
//STYLES
import { TitleStep, Paginator } from '../styles';
//COMPONENTS
import { Row, Col } from '../../../components/GRID';
import { Documents } from '../../Document';
import { useProposal } from '../../../hooks/AppContext';
import { FileType } from '../../../enums/file.enum';
export const Document = () => {
  const { formOpened, setNextStepDisabled } = useProposal();
  const documents = formOpened.documents;
  const countPendency = (): number => {
    const pendencyDocyments = formOpened.loanDetails[0].documentsProposal.map((document) => {
      if (document.status === 'true' || document.status === 'INVALID_DOCUMENT') {
        return true;
      }
    });
    if (pendencyDocyments.length === 0) return 0;
    return pendencyDocyments.filter((item) => item === true).length;
  };
  useEffect(() => {
    if (formOpened.documents !== null) {
      const isInss = formOpened.cnpjConsigneeName === 'Aposentados e Pensionistas INSS';
      if (isInss) {
        if (countPendency() > 0) {
          if (documents.length === countPendency()) {
            setNextStepDisabled(false);
          } else {
            setNextStepDisabled(true);
          }
        } else {
          const personalDoc = documents.filter(
            (docType: any) =>
              docType.documentType == FileType.PERSONAL_IDENTIFICATION ||
              docType.documentType == FileType.PERSONAL_IDENTIFICATION_BACK,
          );
          if (personalDoc.length === 2) {
            setNextStepDisabled(false);
          } else {
            setNextStepDisabled(true);
          }
        }
      } else {
        if (documents.length === 0) {
          setNextStepDisabled(true);
          return;
        }
        documents.map((docType: any) => {
          if (formOpened.documentList) {
            formOpened.documentList.map((pendenceDoc: any) => {
              if (pendenceDoc.documentType == docType.documentType) {
                if (pendenceDoc.status == 'true' || pendenceDoc.status == 'INVALID_DOCUMENT') {
                  setNextStepDisabled(false);
                }
              }
            });
          }
          if (
            docType.documentType == FileType.PERSONAL_IDENTIFICATION ||
            docType.documentType == FileType.OTHER_ONE ||
            docType.documentType == FileType.OTHER_TWO ||
            docType.documentType == FileType.OTHER_THREE ||
            docType.documentType == FileType.OTHER_FOUR ||
            docType.documentType == FileType.PAYROLL
          ) {
            setNextStepDisabled(false);
          }
        });
      }
    } else {
      setNextStepDisabled(true);
    }
  }, [formOpened]);
  return (
    <Row>
      <Col sm={18} md={18} lg={18}>
        <Paginator>3/5</Paginator>
        <TitleStep>Envio de documentos</TitleStep>
        <Row>
          <Col sm={18} md={14} lg={14}>
            <Documents />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
