import { Modals } from '../enums/portals.enum';

//COMPONENTS
import {
  ModalConfirmationProposal,
  ModalErrorProposal,
  ModalErrorDocument,
  ModalErrorProposalInconsistent,
  ModalAnalysisProposal,
  ModalPendingDocument,
  ModalLinkProposal,
  ModalBanner,
  ModalPendenceDocuments,
  ModalErrorProposalGeneric,
  ModalPurchaseMargin,
} from '../components/UI/Modal/templates';

export const ModalList = {
  [Modals.CONFIRMATIONPROPOSAL]: ModalConfirmationProposal,
  [Modals.ERRORPROPOSAL]: ModalErrorProposal,
  [Modals.ERRORDOCUMENT]: ModalErrorDocument,
  [Modals.ERRORPROPOSALGENERIC]: ModalErrorProposalGeneric,
  [Modals.ERRORPROPOSALINCONSISTENT]: ModalErrorProposalInconsistent,
  [Modals.ANALYSISPROPOSAL]: ModalAnalysisProposal,
  [Modals.PENDINGDOCUMENT]: ModalPendingDocument,
  [Modals.LINKPROPOSAL]: ModalLinkProposal,
  [Modals.MODALBANNER]: ModalBanner,
  [Modals.MODALPENDENCEDOCUMENTS]: ModalPendenceDocuments,
  [Modals.MODALPURCHASEMARGIN]: ModalPurchaseMargin,
};
