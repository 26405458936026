// @ts-nocheck
import React, { useEffect, useState, ReactElement, useCallback } from 'react';

import {
  ContentSteps,
  HeaderSteps,
  TitleSteps,
  ContentProposal,
  ModalAction,
  ActionSignProposal,
  LinkDownload,
} from '../styles';

// CONTEXT
import { useSignature } from '../../../../hooks/SignatureContext';

// SERVICE
import SignatureService from '../../../../services/SignatureService';

//SVG
import { ArrowLeft, IconDownload } from '../../Form/svgs';

// COMPONENTS
import { ProposalConsig, ProposalFgts } from '../../Proposal';
import { Modal } from '../../../../components/UISignatue/Modal';
import { Button } from '../../../../components/UISignatue/Button';
import { Checkbox } from '../../../../components/UISignatue/Checkbox';
import { Loader } from '../../../../components/UISignatue/Loader';

import { ArrowRight } from '../svgs';
import { ConditionsAndClausesLabel } from '../../../../enums/terms.enum';
import Signature from '../../../../interfaces/Signature';

interface IProposaList {
  formValues: Signature;
}

export const ProposaList = ({ formValues }: IProposaList) => {
  const {
    formOpened,
    setFormOpened,
    showModal,
    setShowModal,
    setStep,
    nextStepDisabled,
    setNextStepDisabled,
    loading,
    setTermId,
    nextStep,
  } = useSignature();
  const [data, setData] = useState<any | null>([]);
  const [fetchloading, setFetchLoading] = useState(false);
  const [terms, setTerms] = useState([]);
  // Checkbox
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState<any | null>([]);

  const handleListProposal = async () => {
    try {
      setFetchLoading(true);
      const fetchProposal = await SignatureService.fetchProposal(formOpened.token);
      const { data } = fetchProposal;
      setData(data);
      await handleFileCcb(data);
    } catch (error) {
      console.error(error);
    } finally {
      setFetchLoading(false);
    }
  };

  // Modal
  const handleNextstepModal = useCallback(
    (e: any) => {
      formValues.proposals = isCheck;
      setFormOpened(formValues);
      setShowModal(false);
      sendAcceptDraftContract(e, formValues);
    },
    [formValues],
  );

  // CheckBox
  const handleSelectAll = (e: any) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(
      data.map((li: any) => {
        setTermId(li.benefitCardTermId);
        return li.proposalNumber;
      }),
    );

    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e: any) => {
    const { id, checked, dataset } = e.target;
    if (dataset.termid && checked) {
      setTermId(dataset.termid);
    } else {
      setTermId('');
    }
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item: any) => item !== id));
    }
  };

  const sendAcceptDraftContract = async (e: any, formValues: Signature) => {
    setFetchLoading(true);
    try {
      const fetchProposalSignature = await SignatureService.acceptDraftContract(formValues);
      const { status } = fetchProposalSignature;

      if (status === 204) {
        nextStep(e);
        setFetchLoading(false);
      } else {
        throw new Error('Error Signature');
      }
    } catch (err) {
      console.error(err, 'error');
      setFetchLoading(false);
      setErrorSignature(true);
    }
  };

  // ActionPage Consig
  const handleSignedProposalConsig = (e) => {
    if (isCheck.length === data.length) {
      formValues.proposals = isCheck;
      setFormOpened(formValues);
      sendAcceptDraftContract(e, formValues);
    } else {
      setShowModal(true);
    }
  };
  // ActionPage Fgsts
  const handleSignedProposalFgts = (e) => {
    formValues.proposals = [data[0].proposalNumber];
    setFormOpened(formValues);
    sendAcceptDraftContract(e, formValues);
  };

  const handlePrevstep = () => {
    setStep((previousStep: any) => previousStep - 1);
  };

  useEffect(() => {
    if (formOpened.type === 'FGTS_ANTICIPATION') {
      setNextStepDisabled(false);
    } else if (isCheck.length >= 1) {
      setNextStepDisabled(false);
    } else {
      setNextStepDisabled(true);
    }
  }, [isCheck]);

  useEffect(() => {
    handleListProposal();
  }, []);

  const handleFileCcb = async (proposalsResp: any) => {
    let requests = [];
    let haveRetentionCondition = true;

    let type = 'PAYROLL_LOAN_CCB';
    if (
      proposalsResp.filter((item: any) => item.benefitCardTermId === 'PAYROLL_LOAN_BENEFIT_CARD_PREF_CR').length > 0
    ) {
      type = 'PAYROLL_LOAN_CCB_BENEFIT_CARD';
    }
    if (formOpened.type === 'FGTS_ANTICIPATION') {
      haveRetentionCondition = false;
      requests.push(SignatureService.conditionsAndClauses('PAYROLL_LOAN_CCB_FGTS'));
    }

    if (haveRetentionCondition) {
      requests.push(
        SignatureService.conditionsAndClauses(type),
        SignatureService.conditionsAndClauses('PAYROLL_LOAN_CCB_RETENTION'),
      );

      if (
        proposalsResp.filter((item: any) => item.cnpjConsigneeName === 'Aposentados e Pensionistas INSS').length > 0
      ) {
        requests.push(
          SignatureService.conditionsAndClauses(
            'PAYROLL_LOAN_INSS',
            proposalsResp.filter((item: any) => item.cnpjConsigneeName === 'Aposentados e Pensionistas INSS')[0]
              .proposalNumber,
          ),
        );
      }

      const request = await Promise.all(requests);
      const termsList = request.map(({ data }) => ({
        type: data.id,
        label: ConditionsAndClausesLabel[data.id],
        pdf: data.url,
        html: data.html,
      }));
      setTerms(termsList);
      formValues.terms = termsList.map((data) => data.type);
      setFormOpened(formValues);
    } else {
      const request = await Promise.all(requests);

      const termsList = [
        {
          type,
          label: ConditionsAndClausesLabel[request.data.id],
          pdf: request.data.url,
          html: request.data.html,
        },
      ];
      setTerms(termsList);
      formValues.terms = termsList.map((data) => data.type);
      setFormOpened(formValues);
    }
  };

  return (
    <ContentSteps>
      <HeaderSteps>
        <button onClick={handlePrevstep}>
          <ArrowLeft />
        </button>
        <TitleSteps>Minhas propostas</TitleSteps>
      </HeaderSteps>

      <ContentProposal>
        {fetchloading ? (
          <>
            <Loader black title="Carregando propostas" />
          </>
        ) : (
          <>
            {formOpened.type === 'FGTS_ANTICIPATION' ? (
              <>
                {data.map((item: any, id: any) => {
                  return <ProposalFgts formValues={item} key={id} />;
                })}
              </>
            ) : (
              <>
                <Checkbox
                  type="checkbox"
                  name="selectAll"
                  id="selectAll"
                  handleClick={handleSelectAll}
                  isChecked={isCheckAll}
                  title={'Selecionar todas as propostas' + ` (${data.length})`}
                  border
                  big
                />
                {data.map((item: any, id: any) => {
                  return (
                    <ProposalConsig
                      formValues={item}
                      key={id}
                      id={item.proposalNumber}
                      handleClick={handleClick}
                      isChecked={isCheck.includes(item.proposalNumber)}
                    />
                  );
                })}
              </>
            )}
            {terms.map((term, key) => (
              <LinkDownload key={`Conditions_Clauses_${key}`}>
                <IconDownload />
                <a download href={term.pdf}>
                  {term.label}
                </a>
              </LinkDownload>
            ))}
          </>
        )}
      </ContentProposal>

      {/* Modal */}
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        title={'Você não selecionou todas as propostas'}
        description={
          'Você selecionou apenas ' +
          ` (${isCheck.length})` +
          ' proposta de um total de ' +
          ` (${data.length})` +
          '. Se você esqueceu de selecionar alguma proposta, é só clicar em voltar. Caso você tenha selecionado corretamente, é só clicar em prosseguir.'
        }
      >
        <ModalAction>
          <Button black big spaced onClick={() => setShowModal(false)} iconRight={ArrowRight}>
            Voltar
          </Button>
          <Button transparent big spaced onClick={handleNextstepModal}>
            Prosseguir assim mesmo
          </Button>
        </ModalAction>
      </Modal>
      {/* IF PROPOSAL ACTION */}
      {formOpened.type === 'FGTS_ANTICIPATION' ? (
        <ActionSignProposal>
          <Button
            black
            big
            spaced
            onClick={handleSignedProposalFgts}
            iconRight={ArrowRight}
            {...{ disabled: fetchloading }}
          >
            Assinar proposta
          </Button>
        </ActionSignProposal>
      ) : (
        <ActionSignProposal>
          <Button
            black
            big
            spaced
            onClick={handleSignedProposalConsig}
            iconRight={ArrowRight}
            {...{ disabled: nextStepDisabled || loading || fetchloading }}
          >
            Assinar propostas ({isCheck.length} de {data.length})
          </Button>
        </ActionSignProposal>
      )}
    </ContentSteps>
  );
};
