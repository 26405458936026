import Signature from '../interfaces/Signature';
import SignatureResource from '../resource/SignatureResource';

export default class SignatureService {
  static fetchSignature = async (token?: any) => {
    return SignatureResource.fetchSignature(token)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
  static fetchProposal = async (token?: any) => {
    return SignatureResource.fetchProposal(token)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static fetchTermsWithId = async (termId: string) => {
    return SignatureResource.fetchTermsWithId(termId)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static fetchTerms = async () => {
    return SignatureResource.fetchTerms()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static acceptTerms = async (data: Signature) => {
    return SignatureResource.acceptTerms(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static acceptCustomTerms = async (termId: string, data: Signature) => {
    return SignatureResource.acceptCustomTerms(termId, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static acceptDraftContract = async (data: any) => {
    return SignatureResource.acceptDraftContract(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static conditionsAndClauses = async (type: string, proposalNumber?: string) => {
    return SignatureResource.conditionsAndClauses(type, proposalNumber);
  };

  static conditionsAndClausesRetention = async () => {
    return SignatureResource.conditionsAndClausesRetention()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static conditionsAndClausesFgts = async () => {
    return SignatureResource.conditionsAndClausesFgts()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static conditionsAndClausesConsig = async () => {
    return SignatureResource.conditionsAndClausesConsig()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };

  static sendSelfie = async (data: any) => {
    return SignatureResource.sendSelfie(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
  static sendProposal = async (data: any) => {
    return SignatureResource.sendProposal(data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  };
}
