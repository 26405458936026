// @ts-nocheck
import React, { ReactElement, useState, createRef, useCallback, useMemo } from 'react';
import { Proposal } from '../../models/Proposal';
import { InputUpload } from '../../components/UI/InputUpload';
import { ContentDataPerson, MarginBottom16, DisclaimerWrapper, Disclaimer } from './styles';
import { DisclaimerSvg, OthersSvg } from './svgs';
import { ProponentDocuments, ProponentDocumentsInss } from '../../constants/proponent-documents';
import { getExactOtherFile } from '../../utils/files';

// CONTEXT
import { useProposal } from '../../hooks/AppContext';

// ENUMS
import { FileType } from '../../enums/file.enum';

interface DocumentsPersonalProps {
  onView: any;
  onDelete: any;
  onUpload: any;
  onCancel: any;
  proposal: Proposal;
}

export const DocumentsPersonal = ({
  onView,
  onDelete,
  onUpload,
  onCancel,
  proposal,
}: DocumentsPersonalProps): ReactElement => {
  const { formOpened } = useProposal();

  const isInss = formOpened.cnpjConsigneeName === 'Aposentados e Pensionistas INSS';

  const [inputsRef, setInputsRef] = useState(isInss ? ProponentDocumentsInss : ProponentDocuments);

  const listRefs = useMemo(
    () =>
      Array(inputsRef.length)
        .fill(0)
        .map(() => createRef()),
    [inputsRef],
  );

  const otherInputRefs = useMemo(
    () =>
      Array(4)
        .fill(0)
        .map(() => createRef()),
    [],
  );

  const allowDocumentUpload = (documentType: string): boolean => {
    if (!formOpened.loanDetails || formOpened.loanDetails.length == 0) return true;
    const isPending = formOpened.loanDetails.some((loanDetail) => {
      if (!loanDetail.documentsProposal || loanDetail.documentsProposal.length == 0) return true;
      return loanDetail.documentsProposal.some((document) => {
        if (
          (document.status === 'true' || document.status === 'INVALID_DOCUMENT') &&
          document.documentType === documentType
        ) {
          return true;
        }
        return false;
      });
    });
    return isPending;
  };

  const renderOtherDocuments = useCallback(() => {
    return (
      <>
        <React.Fragment key={`other-personal-documents`}>
          <InputUpload
            id={`other-${0}`}
            ref={otherInputRefs[0]}
            deleteCallback={() => onDelete(proposal, FileType.OTHER_ONE, 'Proposal', '')}
            file={getExactOtherFile(proposal.documents, FileType.OTHER_ONE)}
            wasFile={getExactOtherFile(proposal.documents, FileType.OTHER_ONE)}
            icon={<OthersSvg />}
            handleCancel={() => onCancel(otherInputRefs[0])}
            handleFile={(file: File) => onUpload(file, FileType.OTHER_ONE, otherInputRefs[0])}
            label={<span>Outros documentos</span>}
            subtitle="Outros documentos importantes a proposta"
            viewDocumentCallback={() => onView(proposal, FileType.OTHER_ONE, 'Proposal', '')}
            disabled={!allowDocumentUpload(FileType.OTHER_ONE)}
          />
          <MarginBottom16 />
          <InputUpload
            id={`other-${1}`}
            ref={otherInputRefs[1]}
            deleteCallback={() => onDelete(proposal, FileType.OTHER_TWO, 'Proposal', '')}
            file={getExactOtherFile(proposal.documents, FileType.OTHER_TWO)}
            wasFile={getExactOtherFile(proposal.documents, FileType.OTHER_TWO)}
            icon={<OthersSvg />}
            handleCancel={() => onCancel(otherInputRefs[1])}
            handleFile={(file: File) => onUpload(file, FileType.OTHER_TWO, otherInputRefs[1])}
            label={<span>Outros documentos</span>}
            subtitle="Outros documentos importantes a proposta"
            viewDocumentCallback={() => onView(proposal, FileType.OTHER_TWO, 'Proposal', '')}
            disabled={!allowDocumentUpload(FileType.OTHER_TWO)}
          />
          <MarginBottom16 />
          <InputUpload
            id={`other-${2}`}
            ref={otherInputRefs[2]}
            deleteCallback={() => onDelete(proposal, FileType.OTHER_THREE, 'Proposal', '')}
            file={getExactOtherFile(proposal.documents, FileType.OTHER_THREE)}
            wasFile={getExactOtherFile(proposal.documents, FileType.OTHER_THREE)}
            icon={<OthersSvg />}
            handleCancel={() => onCancel(otherInputRefs[2])}
            handleFile={(file: File) => onUpload(file, FileType.OTHER_THREE, otherInputRefs[2])}
            label={<span>Outros documentos</span>}
            subtitle="Outros documentos importantes a proposta"
            viewDocumentCallback={() => onView(proposal, FileType.OTHER_THREE, 'Proposal', '')}
            disabled={!allowDocumentUpload(FileType.OTHER_THREE)}
          />
          <MarginBottom16 />
          <InputUpload
            id={`other-${3}`}
            ref={otherInputRefs[3]}
            deleteCallback={() => onDelete(proposal, FileType.OTHER_FOUR, 'Proposal', '')}
            file={getExactOtherFile(proposal.documents, FileType.OTHER_FOUR)}
            wasFile={getExactOtherFile(proposal.documents, FileType.OTHER_FOUR)}
            icon={<OthersSvg />}
            handleCancel={() => onCancel(otherInputRefs[3])}
            handleFile={(file: File) => onUpload(file, FileType.OTHER_FOUR, otherInputRefs[3])}
            label={<span>Outros documentos</span>}
            subtitle="Outros documentos importantes a proposta"
            viewDocumentCallback={() => onView(proposal, FileType.OTHER_FOUR, 'Proposal', '')}
            disabled={!allowDocumentUpload(FileType.OTHER_FOUR)}
          />
          <MarginBottom16 />
        </React.Fragment>
      </>
    );
  }, [otherInputRefs, proposal]);

  const renderPersonPF = useCallback(() => {
    return (
      <>
        {inputsRef.map((input: any, idx: number) => {
          const Icon = input.icon;
          const isIdentification = input.name.includes('IDENTIFICATION');
          const maxFileSize = isInss && isIdentification ? 2048000 : 10240000;

          return (
            <React.Fragment key={`document-pf-${idx}`}>
              <InputUpload
                maxFileSize={maxFileSize}
                id={idx}
                ref={listRefs[idx]}
                deleteCallback={() => onDelete(proposal, input.name)}
                file={proposal.documents?.find((val: any) => val.documentType === input.name)}
                wasFile={!!proposal.documents?.find((val: any) => val.documentType === input.name)}
                icon={<Icon />}
                handleCancel={() => onCancel(listRefs[idx])}
                handleFile={(file: File) => onUpload(file, input.name, listRefs[idx])}
                label={input.label}
                subtitle={input.subtitle}
                viewDocumentCallback={() => onView(proposal, input.name)}
                disabled={input.disabled || !allowDocumentUpload(input.name)}
                acceptedFiles={input.acceptedFiles}
                limitResolution={input.limitResolution ? input.limitResolution : null}
              />
              <MarginBottom16 />
            </React.Fragment>
          );
        })}
      </>
    );
  }, [proposal]);

  return (
    <ContentDataPerson nomargin paddingTop>
      {renderPersonPF()}
      {renderOtherDocuments()}
      <DisclaimerWrapper>
        <Disclaimer>
          <DisclaimerSvg />
          <p>O arquivo deve ser no formato JPG, PNG ou PDF com até 10MB.</p>
        </Disclaimer>
        {isInss && (
          <>
            <Disclaimer>
              <DisclaimerSvg />
              <p>
                Os arquivos de identificação Frente e Verso devem ser no formato JPG e ter resolução mínima de 250x250
                pixels.
              </p>
            </Disclaimer>
            <Disclaimer>
              <DisclaimerSvg />
              <p>Arquivo doc de identificação deve ter no máximo 2MB para frente e 2MB para o verso</p>
            </Disclaimer>
          </>
        )}
      </DisclaimerWrapper>
    </ContentDataPerson>
  );
};
