import React, { useEffect, useState, useCallback } from 'react';
import { ContentSteps } from '../styles';
import publicIp from 'public-ip';

// Context
import { useSignature } from '../../../../hooks/SignatureContext';

// Service
import SignatureService from '../../../../services/SignatureService';
// Component
import { Loader } from '../../../../components/UISignatue/Loader';

import { ErrorPermissions } from '../../ScreensError/index';
// SVG
import { IconErrorPermissionGeolocation } from '../../ScreensError/svg';
import Signature from '../../../../interfaces/Signature';

interface ICustomTerms {
  formValues: Signature;
}

export const CustomTerms = ({ formValues }: ICustomTerms) => {
  const {
    setFormOpened,
    setNextStepDisabled,
    setErrorGelocation,
    errorGelocation,
    termId,
    terms,
    setTerms,
    indexAssinedTerm,
  } = useSignature();
  const [loading, setLoading] = useState(false);

  const handleFormInput = useCallback(() => {
    setFormOpened(formValues);
  }, [formValues]);

  useEffect(() => {
    const handleFecthTerms = async () => {
      try {
        setLoading(true);
        setNextStepDisabled(true);
        const fetchTerms = await SignatureService.fetchTermsWithId(termId);
        setTerms(fetchTerms?.data);
        setLoading(false);
        setNextStepDisabled(false);
      } catch (error) {
        console.log(error);
      }
    };
    handleFecthTerms();
  }, []);

  useEffect(() => {
    const getClientIp = async () => {
      try {
        const ipV4 = await publicIp.v4();
        formValues.customerIP = ipV4;
      } catch (error) {
        console.log(error);
      }
    };
    getClientIp();
    formValues.token = localStorage.getItem('token') || '';
  }, [formValues]);

  useEffect(() => {
    const getPosition = async () => {
      const geolocation = navigator.geolocation;
      try {
        await geolocation.getCurrentPosition(
          (position) => {
            formValues.customerLatitude = String(position.coords.latitude);
            formValues.customerLongitude = String(position.coords.longitude);
          },
          () => {
            setNextStepDisabled(true);
            setErrorGelocation(true);
            console.log(new Error('Permission denied'));
          },
        );
      } catch (error) {
        console.log(error);
      }
    };
    getPosition();
  }, [formValues]);

  useEffect(() => {
    handleFormInput();
  }, []);

  return (
    <ContentSteps className={`${errorGelocation ? 'errorPermissionGeolocation' : 'terms'}`}>
      {errorGelocation ? (
        <ErrorPermissions
          titlePage={'Localização'}
          cardTitle={'Sem acesso à localização'}
          cardFooterlink={'https://support.google.com/chrome/answer/2693767?hl=pt-BR&co=GENIE.Platform%3DAndroid&oco=0'}
          cardFooterText={'Como permitir acesso à localização'}
          icon={IconErrorPermissionGeolocation}
        >
          Por questões de segurança, precisamos ter acesso a localização do seu celular para confirmar a assinatura.
          <br />
          <br />
          Permita acesso à localização do dispositivo para continuar
        </ErrorPermissions>
      ) : (
        <>
          {/* <TitleSteps>Termos de uso</TitleSteps> */}
          {loading ? (
            <>
              <Loader black title="Carregando termos de uso" />
            </>
          ) : (
            <iframe id="myIframe" src={terms[indexAssinedTerm]?.html} title="Termos de uso Assinatura" />
          )}
        </>
      )}
    </ContentSteps>
  );
};
