// @ts-nocheck
import { Status } from '../enums/proposal-status.enum';
import ProposalInterface from '../interfaces/Proposal';

export class Proposal {
  public id: string;
  public name: string;
  public documentNumber: number;
  public phoneNumber: string;
  public cnpjConsigneeId: any;
  public created: string;
  public type: string;
  public status: Status;
  public url: string;
  public proposalNumber: string;
  public loanDetails: Array<any>;
  public documents: Array<any>;
  public loanType: number;
  public cnpjConsigneeName: string;
  public documentList: Array<any>;
  public purchaseMargin: number;

  constructor(data: ProposalInterface) {
    this.id = data?.id;
    this.name = data?.name;
    this.documentNumber = data?.documentNumber;
    this.phoneNumber = data?.phoneNumber;
    this.cnpjConsigneeId = data?.cnpjConsigneeId;
    this.created = data?.created;
    this.type = data?.type;
    this.status = data?.status;
    this.url = data?.url;
    this.proposalNumber = data?.proposalNumber;
    this.loanDetails = data?.loanDetails ?? [];
    this.documents = data?.documents ?? [];
    this.loanType = data?.loanType;
    this.cnpjConsigneeName = data?.cnpjConsigneeName;
    this.documentList = data?.documentList ?? [];
    this.purchaseMargin = data?.purchaseMargin;

    return this;
  }
}
