// @ts-nocheck
import { createContext, useContext, useState } from 'react';
import { Proposal } from '../models/Proposal';
import ProposalService from '../services/ProposalService';
import { tableColumnStatus } from '../utils/table';

// CONSTANTS
import { pageSizes } from '../constants/grid';

// ENUMS
import { Modals } from '../enums/portals.enum';

// CONTEXT
import { usePortals } from '../hooks/PortalsContext';
import { normalizeProposal } from '../utils/proposal';
import { Status } from '../enums/proposal-status.enum';
import { useNavigate } from 'react-router-dom';

export const ProposalContext = createContext({});
export const useProposal = (): any => useContext(ProposalContext);

const getPageSize = (pageSize = 10) => pageSizes.filter((size) => size.value == pageSize);

const ProposalProvider = ({ children }: any) => {
  const { handleOpenPortal, handleClosePortal } = usePortals();
  const [formOpened, setFormOpened] = useState(new Proposal());
  const [cnpjconsignees, setCnpjconsignees] = useState([]);
  const [stepLoading, setStepLoding] = useState(false);
  const [nextStepDisabled, setNextStepDisabled] = useState(false);
  const [prevStepDisabled, setPrevStepDisabled] = useState(false);
  const [pageSize, setPageSize] = useState(getPageSize());
  const [toast, setToast] = useState({
    opened: false,
    message: '',
  });
  const [externalCpfError, setExternalCpfError] = useState('');
  const [tableFilters, setTableFilters] = useState({});
  const [tableConfiguration, setTableConfiguration] = useState(tableColumnStatus);
  const [tableSearch, setTableSearch] = useState('');
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  const isCwbCardPendence =
    formOpened?.status === 'PENDING_PURCHASE_MARGIN' || formOpened?.status === 'PENDING_PURCHASE_MARGIN_WRONG_PROPOSAL';

  const changeTableConfiguration = (items: any) => {
    setTableConfiguration((prevItems) => [...prevItems, items]);
  };

  const clearForm = () => {
    setFormOpened(new Proposal());
    setStep(1);
  };

  const verifyPendenceOnProposalDocuments = (proposal: Proposal): boolean => {
    if (proposal.loanDetails.length > 1) {
      const hasPendence = proposal.loanDetails.some((loanDetail) => {
        return loanDetail.documentsProposal.some((document) => {
          if (document.status === 'true' || document.status === 'INVALID_DOCUMENT') {
            return true;
          }
        });
      });
      if (hasPendence) {
        handleOpenPortal(Modals.MODALPENDENCEDOCUMENTS);
        return false;
      }
    }
    return true;
  };

  const handleStep1 = async () => {
    setStep((previousStep: any) => previousStep + 1);
    setStepLoding(false);
  };

  const handleStep2 = async () => {
    setStepLoding(true);
    setPrevStepDisabled(true);
    const fetchData = await ProposalService.createProposal(normalizeProposal(formOpened));
    if (fetchData === undefined) {
      handleOpenPortal(Modals.ERRORPROPOSALGENERIC);
    } else {
      const { response } = fetchData;
      if (response != null) {
        if (response.status === 400 && response.data.validationErrors === undefined) {
          setStepLoding(false);
          setPrevStepDisabled(false);
          return false;
        } else if (response.status === 400 && response.data.validationErrors.invalidProposalNumber) {
          setStepLoding(false);
          setPrevStepDisabled(false);
          handleOpenPortal(Modals.ERRORPROPOSAL);
        } else if (response.data.validationErrors.invalidDocument || response.data.validationErrors.Consignee) {
          setStepLoding(false);
          setPrevStepDisabled(false);
          handleOpenPortal(Modals.ERRORPROPOSAL);
        } else if (response.data.validationErrors.documentAlreadyRegistered) {
          setStepLoding(false);
          setPrevStepDisabled(false);
          handleOpenPortal(Modals.ERRORPROPOSALINCONSISTENT);
        } else if (response.data.validationErrors.proposalRefused) {
          setStepLoding(false);
          setPrevStepDisabled(false);
          handleOpenPortal(Modals.ANALYSISPROPOSAL);
        }
        setStepLoding(false);
        setPrevStepDisabled(false);
        return false;
      }

      const shouldNavigateToManageProposal =
        formOpened.status === Status.PENDING_PURCHASE_MARGIN ||
        formOpened.status === Status.PENDING_PURCHASE_MARGIN_WRONG_PROPOSAL;

      if (shouldNavigateToManageProposal) {
        setStepLoding(false);
        setPrevStepDisabled(false);
        navigate('/manage-proposal');
        return false;
      }

      setStepLoding(false);
      setPrevStepDisabled(false);
      setFormOpened({
        ...formOpened,
        ...fetchData.data,
        loanDetails: formOpened.loanDetails,
      });
      setStep((previousStep: any) => previousStep + 1);
      if (!fetchData) return;
    }
  };

  const verifyCwbCard = (proposal: Proposal): boolean => {
    if (proposal.cnpjConsigneeName === 'Cartão Benefício da Prefeitura de Curitiba') {
      if (proposal.loanDetails.some((loanDetail) => loanDetail.purchaseMargin > 0)) return true;
      return false;
    }
    return true;
  };

  const prevStep = () => {
    setStep((previousStep: any) => previousStep - 1);
  };

  const nextStep = (e: any) => {
    e.preventDefault();
    if (step === 1) {
      setStepLoding(true);
      handleStep1();
    }
    if (step === 2) {
      setStepLoding(true);
      if (verifyPendenceOnProposalDocuments(formOpened)) {
        if (verifyCwbCard(formOpened)) {
          handleStep2();
        } else {
          handleOpenPortal(Modals.MODALPURCHASEMARGIN, {
            onConfirm: async () => {
              handleClosePortal();
              await handleStep2();
            },
          });
          setStepLoding(false);
        }
      } else {
        setStepLoding(false);
      }
    }
    if (step === 3) {
      setStep((previousStep: any) => previousStep + 1);
    }
    if (step === 4) {
    }
  };

  const values = {
    formOpened,
    changeTableConfiguration,
    setFormOpened,
    stepLoading,
    setStepLoding,
    toast,
    setToast,
    externalCpfError,
    setExternalCpfError,
    nextStepDisabled,
    setNextStepDisabled,
    prevStepDisabled,
    setPrevStepDisabled,
    step,
    setStep,
    prevStep,
    nextStep,
    tableConfiguration,
    setPageSize,
    pageSize,
    getPageSize,
    tableFilters,
    setTableFilters,
    tableSearch,
    setTableSearch,
    cnpjconsignees,
    setCnpjconsignees,
    clearForm,
    isCwbCardPendence,
  };

  return <ProposalContext.Provider value={{ ...values }}>{children}</ProposalContext.Provider>;
};

export { ProposalProvider };
