import axios from '../axios';
import { PREFIX, VERSION } from '../enums/request';
import Signature from '../interfaces/Signature';

export default class SignatureResource {
  static fetchSignature(token?: any) {
    return axios.get(`/${PREFIX.PARTNERS}/${VERSION.V2}/payrollloanproposals/links?token=${token}`);
  }

  static fetchTermsWithId(termId: string) {
    return axios.get(`/${PREFIX.PARTNERS}/${VERSION.V2}/payrollloanproposals/benefit-card-terms?termId=${termId}`);
  }

  static fetchTerms() {
    return axios.get(`/${PREFIX.PARTNERS}/${VERSION.V2}/payrollloanproposals/terms`);
  }

  static fetchProposal(token?: any) {
    return axios.get(`/${PREFIX.PARTNERS}/${VERSION.V2}/payrollloanproposals/links/proposals?token=${token}`);
  }

  static acceptTerms(data: Signature) {
    return axios
      .put(`/${PREFIX.PARTNERS}/${VERSION.V2}/payrollloanproposals/terms`, data)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
  }

  static acceptCustomTerms(termId: string, data: Signature) {
    return axios
      .put(`/${PREFIX.PARTNERS}/${VERSION.V2}/payrollloanproposals/accept-benefit-card-terms`, {
        ...data,
        termId,
      })
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
  }

  static acceptDraftContract(data: any) {
    return axios
      .put(`/${PREFIX.PARTNERS}/${VERSION.V2}/payrollloanproposals/acceptDraftContract`, data)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
  }

  static conditionsAndClauses(type: string, proposalNumber?: string) {
    if (proposalNumber) {
      return axios.get(
        `/${PREFIX.PARTNERS}/${VERSION.V2}/payrollloanproposals/terms?termId=${type}&proposalNumber=${proposalNumber}`,
      );
    }
    return axios.get(`/${PREFIX.PARTNERS}/${VERSION.V2}/payrollloanproposals/terms?termId=${type}`);
  }

  static conditionsAndClausesRetention() {
    return axios.get(`/${PREFIX.PARTNERS}/${VERSION.V2}/payrollloanproposals/terms?termId=PAYROLL_LOAN_CCB_RETENTION`);
  }
  static conditionsAndClausesFgts() {
    return axios.get(`/${PREFIX.PARTNERS}/${VERSION.V2}/payrollloanproposals/terms?termId=PAYROLL_LOAN_CCB_FGTS`);
  }

  static conditionsAndClausesConsig() {
    return axios.get(`/${PREFIX.PARTNERS}/${VERSION.V2}/payrollloanproposals/terms?termId=PAYROLL_LOAN_CCB`);
  }

  static sendSelfie(data: any) {
    return axios
      .put(`/${PREFIX.PARTNERS}/${VERSION.V2}/payrollloanproposals/links/selfies`, data)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
  }

  static sendProposal(data: any) {
    return axios
      .put(`/${PREFIX.PARTNERS}/${VERSION.V2}/payrollloanproposals/links/signatures`, data)
      .then((resp) => {
        return resp;
      })
      .catch((err) => {
        return err;
      });
  }
}
