import React, { useState, useRef } from 'react';

// HOOKS
import useOnClickOutside from '../../../hooks/useOnClickOutside';

// UTIL
import { numberFormat } from '../../../utils/Signature/formatCurrency';
import { dateFormat } from '../../../utils/Signature/formatDate';

// COMPONENT
import { Checkbox } from '../../../components/UISignatue/Checkbox';
import { Spreadsheet, ActionConsig, Calendar, Chart } from '../Form/svgs';

//STYLES
import {
  ContainerCardProposal,
  HeaderCardProposal,
  Icon,
  TitleCardProposal,
  DescriptionContent,
  DescriptionValueProposal,
  TextValueProposal,
  ValueProposal,
  AccordionContent,
  AccordionCard,
  IconAccordion,
  AccordionContentText,
  AccordionText,
  Paragraph,
  SubParagraph,
  FooterCardProposal,
  ActionCollapse,
} from './styles';

export const ProposalConsig = ({ formValues, handleClick, isChecked, id }: any) => {
  const content = useRef<HTMLInputElement>(null);
  const sensitive = useRef<HTMLInputElement>(null);
  const [rotation, setRotation] = useState('accordion__icon');
  const [active, setActive] = useState('');
  const [height, setHeight] = useState('0px');

  const toggleAccordion = () => {
    setActive(active === '' ? 'active' : '');
    setHeight(active === 'active' ? '0px' : `${content.current?.scrollHeight}px`);
    setRotation(active === 'active' ? 'accordion__icon' : 'accordion__icon rotate');
  };

  useOnClickOutside(sensitive, () => {
    setActive('');
    setHeight('0px');
    setRotation('accordion__icon');
  });

  return (
    <ContainerCardProposal className={'accordion__section, ' + (isChecked ? 'selected' : '')} ref={sensitive}>
      <HeaderCardProposal>
        <Icon>
          <Spreadsheet />
        </Icon>
        <TitleCardProposal>Proposta {formValues.proposalNumber.slice(0, 5)}</TitleCardProposal>
      </HeaderCardProposal>
      <DescriptionContent>
        <DescriptionValueProposal>
          <TextValueProposal>Valor líquido do empréstimo</TextValueProposal>
          <ValueProposal>{numberFormat(formValues.commercialConditions.releasedValue / 100)}</ValueProposal>
        </DescriptionValueProposal>
        <DescriptionValueProposal>
          <TextValueProposal>Valor da parcela</TextValueProposal>
          <ValueProposal>{numberFormat(formValues.commercialConditions.installmentValue / 100)}</ValueProposal>
        </DescriptionValueProposal>
        {formValues.loanDetails[0].purchaseMargin > 0 && (
          <DescriptionValueProposal>
            <TextValueProposal>Valor do limite para compras</TextValueProposal>
            <ValueProposal>{numberFormat(formValues.loanDetails[0].purchaseMargin / 100)}</ValueProposal>
          </DescriptionValueProposal>
        )}
        {formValues?.commercialConditions?.remainingValue > 0 && (
          <DescriptionValueProposal>
            <TextValueProposal>Valor restante do benefício mensal</TextValueProposal>
            <ValueProposal>{numberFormat(formValues.commercialConditions.remainingValue / 100)}</ValueProposal>
          </DescriptionValueProposal>
        )}
      </DescriptionContent>
      <AccordionContent ref={content} style={{ maxHeight: `${height}` }} className="accordion__content">
        <AccordionCard>
          <IconAccordion>
            <Calendar />
          </IconAccordion>
          <AccordionContentText>
            <AccordionText>
              <Paragraph>Valor total do empréstimo</Paragraph>
              <SubParagraph>{numberFormat(formValues.commercialConditions.requestedValue / 100)}</SubParagraph>
            </AccordionText>
            <AccordionText>
              <Paragraph>IOF</Paragraph>
              <SubParagraph>{numberFormat(formValues.commercialConditions.iofValue / 100)}</SubParagraph>
            </AccordionText>
          </AccordionContentText>
        </AccordionCard>
        <AccordionCard>
          <IconAccordion>
            <Calendar />
          </IconAccordion>
          <AccordionContentText>
            <AccordionText>
              <Paragraph>Prazo do contrato</Paragraph>
              <SubParagraph>{formValues.commercialConditions.deadLine} meses</SubParagraph>
            </AccordionText>
          </AccordionContentText>
        </AccordionCard>
        <AccordionCard>
          <IconAccordion>
            <Chart />
          </IconAccordion>
          <AccordionContentText>
            <AccordionText>
              <Paragraph>Taxa de Juros mensal nominal</Paragraph>
              <SubParagraph>{formValues.commercialConditions.monthlyFee / 100}% ao mês</SubParagraph>
            </AccordionText>
            <AccordionText>
              <Paragraph>Taxa de Juros anual nominal</Paragraph>
              <SubParagraph>{formValues.commercialConditions.annualFee / 100}% ao ano</SubParagraph>
            </AccordionText>
            <AccordionText>
              <Paragraph>Custo Efetivo Total (CET) mensal</Paragraph>
              <SubParagraph>{formValues.commercialConditions.monthlyCet / 100}% ao mês</SubParagraph>
            </AccordionText>
            <AccordionText>
              <Paragraph>Custo Efetivo Total (CET) anual</Paragraph>
              <SubParagraph>{formValues.commercialConditions.annualCet / 100}% ao ano</SubParagraph>
            </AccordionText>
          </AccordionContentText>
        </AccordionCard>
        <AccordionCard>
          <IconAccordion>
            <Calendar />
          </IconAccordion>
          <AccordionContentText>
            <AccordionText>
              <Paragraph>Primeiro vencimento</Paragraph>
              <SubParagraph>{dateFormat(formValues.commercialConditions.dueDate)}</SubParagraph>
            </AccordionText>
          </AccordionContentText>
        </AccordionCard>
      </AccordionContent>
      <FooterCardProposal>
        <Checkbox
          id={id}
          type="checkbox"
          name={formValues.name}
          handleClick={handleClick}
          isChecked={isChecked}
          title={'Selecione para assinar'}
          termId={formValues.benefitCardTermId}
        />
        <ActionCollapse className="add-margin" onClick={toggleAccordion}>
          <ActionConsig />
          Ver detalhes
        </ActionCollapse>
      </FooterCardProposal>
    </ContainerCardProposal>
  );
};
